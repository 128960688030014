import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buy-the-post',
  templateUrl: './buy-the-post.component.html',
  styleUrls: ['./buy-the-post.component.less']
})
export class BuyThePostComponent implements OnInit {
  public images = [
    '../assets/img/uploads/1.jpg', '../assets/img/uploads/2.jpg', '../assets/img/uploads/10.jpg',
    '../assets/img/uploads/11.jpg', '../assets/img/uploads/12.jpg',
    '../assets/img/uploads/7.jpg', '../assets/img/uploads/6.jpg'
  ];
  public displayedImages = [];
  public firstImage=0;

  constructor() {}

  ngOnInit() {
    let temp = [];

    temp.push(this.images[this.firstImage]);
    temp.push(this.images[this.firstImage + 1]);
    temp.push(this.images[this.firstImage + 2]);
    temp.push(this.images[this.firstImage + 3]);

    this.displayedImages = [... temp];
  }

  public slideRight() {
    if (this.firstImage < this.images.length - 1) {
      this.firstImage++;
    }
    this.displayedImages = [];
    this.displayedImages.push(this.images[this.firstImage]);
    this.displayedImages.push(this.images[this.firstImage + 1]);
    this.displayedImages.push(this.images[this.firstImage + 2]);
    this.displayedImages.push(this.images[this.firstImage + 3]);
    this.displayedImages = [... this.displayedImages];
  }

  public slideLeft() {
    if (this.firstImage > 0) {
      this.firstImage--;
    }

    this.displayedImages = [];
    this.displayedImages.push(this.images[this.firstImage]);
    this.displayedImages.push(this.images[this.firstImage + 1]);
    this.displayedImages.push(this.images[this.firstImage + 2]);
    this.displayedImages.push(this.images[this.firstImage + 3]);
    this.displayedImages = [...this.displayedImages];
  }

  private reloadDisplayedImaged() {}

}
