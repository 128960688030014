import { Component, OnInit, Input } from '@angular/core';
import { BigPost } from '../../models/big.post/big.post';
import { ActivatedRoute } from '@angular/router';
import { PostService } from '../../../services/post-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-read-more-post',
  templateUrl: './read-more-post.component.html',
  styleUrls: ['./read-more-post.component.less']
})
export class ReadMorePostComponent implements OnInit {
  private id: number;
  public post: BigPost;
  public error: string;
  public name:string;
  public email:string;
  public message: string;
  public images: any[];
  public url: any;

  constructor(private route: ActivatedRoute, private postService: PostService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) {
    this.post = new BigPost();
  }

  ngOnInit() {    
    window.addEventListener('scroll', this.scroll, true);
    this.images = [];
    this.spinner.show();
    window.scrollTo(0, 0);

    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });

    this.postService.getPost(this.id).subscribe(res => {
      var result = res.json();
      if (result.success != true) {
        this.error = result.error.message;
      } else {
        setTimeout(() => {
          this.images.push({ source: this.post.postImage, alt: 'Description for Image 1', title: 'Title 1' });
          this.post = result.item;
          console.log(this.post);
          if (this.post.iFrameUrl != null) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.post.iFrameUrl);// + "&output=embed";
          }
          console.log(this.url);
          this.spinner.hide();
        }, 2000);
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }


  public OnSelected(selectedImage: string) {
    this.post.postImage = selectedImage;
  }

  scroll = (event: any): void => {
    //if (window.pageYOffset > 33) {
    //  document.getElementById('logImg').classList.add("shrink");
    //} else {
    // // document.getElementById('logImg').classList.remove("shrink");
    // // document.getElementById('logImg').classList.add("smaller");
    //}
  };
}
