import { Component, OnInit, Input } from '@angular/core';
import { PostType } from "../../models/enums/post.type";
import { BigPost } from '../../models/big.post/big.post';
import { Global } from '../../../general/global';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-posts-holder',
  templateUrl: './posts-holder.component.html',
  styleUrls: ['./posts-holder.component.less']
})
export class PostsHolderComponent implements OnInit {
  @Input()
  listOfItems: BigPost[];

  public imageLeft: number;
  public imageRight: number;

  public isAdmin: boolean;
  public display: boolean;
  private messageSub: Subscription;


  constructor(private global: Global, private sanitizer: DomSanitizer, private router: Router,) {
    this.listOfItems = [];
    this.imageLeft = 0;
    this.imageRight = 1;
  }

  ngOnInit() {
    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

  public newPost() {
    this.router.navigate(['/addpost'])
  }

  onShow(display: boolean) {
    this.display = display;
  }
}
