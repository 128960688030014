export class About {
  public aboutMeHeader: string;
  public aboutMeBody: string;
  public aboutMeImage: string;

  public images: string[];

  constructor() {
    this.aboutMeHeader = '';
    this.aboutMeBody = '';
    this.aboutMeImage = '';
    this.images = [];
  }
}
