import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Global } from '../../general/global';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PostService } from '../../services/post-service';
import { BigPost } from '../models/big.post/big.post';
import { PostType } from '../models/enums/post.type';
import { SelectItem } from 'primeng/api';
import { Route, Router } from '@angular/router';
import { FileService } from '../../services/file-service';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.less']
})
export class AddPostComponent implements OnInit {
  public step1: boolean;
  public step2: boolean;
  public step3: boolean;
  public isAdmin: boolean;
  public current: number;
  public one: boolean;
  public two: boolean;
  public three: boolean;
  public four: boolean;
  public five: boolean;

  public noImage:string;

  public fileList: any[];

  
  //let input = new FormData();
  //input.append("file", file);

  public mainImage: SafeUrl;
  public image1: SafeUrl;
  public image2: SafeUrl;
  public image3: SafeUrl;
  public image4: SafeUrl;

  public file: any;

  public types: SelectItem[];
  public selectedType: any;

  public newPostItem: BigPost;

  public imageTemp: SafeUrl;

  private messageSub: Subscription;

  private htmlContent: any;

  constructor(private router: Router,
    private global: Global,
    private sanitizer: DomSanitizer,
    private postService: PostService,
    private fileService: FileService,
    private spinner: NgxSpinnerService) {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.fileList = [];

    this.noImage = '../../assets/img/core-img/no-image.jpg';
  }

  ngOnInit() {

    this.current = 0;
    this.one = true;
    this.two = false;
    this.three = false;
    this.four = false;
    this.five = false;

    this.newPostItem = new BigPost();

    this.types = [
      { label: 'Select Type', value: null },
      {
        label: PostType[PostType.IMAGELEFT],
        value: { id: 1, name: PostType[PostType.IMAGELEFT], type: PostType.IMAGELEFT }
      },
      {
        label: PostType[PostType.IMAGERIGHT],
        value: { id: 2, name: PostType[PostType.IMAGERIGHT], type: PostType.IMAGERIGHT }
      }
    ];

    // this.types = [PostType[PostType.IMAGELEFT], PostType[PostType.IMAGERIGHT]];
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;

    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

  public newPost() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
  }

  public toStep2() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }

  public review() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
  }

  public myUploader(event) {
    console.log(event);

  }

  public before(event) {
    this.imageTemp = this.sanitize(event.files[0].objectURL.changingThisBreaksApplicationSecurity);

  }

  public uploadMain(event, num: number, num2: number) {
    this.fileList[0] = (event.files[0]);
    var tempImage = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
    this.mainImage = this.sanitize(tempImage);
    //this.fileService.uploadPostImage(14, event.files[0], 4).subscribe(res => {
    //  var result = res.json();

    //  if (result.success == true) {
    //    this.newPostItem = result.Item;

    //    console.log(result);
    //  }
    //});
    //this.mainImage = tempImage;
    //var img = new Image();

    //const imageUrl = URL.createObjectURL(tempImage);

    //console.log(imageUrl);
    // console.log(event);
  }

  public uploadOne(event, num: number, num2: number) {
    this.fileList[1] = (event.files[0]);
    //this.FileTest[1]=input;
    var tempImage = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
    this.image1 = this.sanitize(tempImage);
  }
  public uploadTwo(event, num: number, num2: number) {
    this.fileList[2] = (event.files[0]);
   // this.FileTest[2] = input;
    var tempImage = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
    this.image2 = this.sanitize(tempImage);
  }
  public uploadThree(event, num: number, num2: number) {
    this.fileList[3] = (event.files[0]);
    //this.FileTest[3] = input;
    var tempImage = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
    this.image3 = this.sanitize(tempImage);
  }
  public uploadFour(event, num: number, num2: number) {
    this.fileList[4] = (event.files[0]);
   // this.FileTest[4] = input;
    var tempImage = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
    this.image4 = this.sanitize(tempImage);
  }

  public saveImages() {
    console.log(this.fileList);
  }

  public savePost() {
    this.newPostItem.type = this.selectedType.type;
   // this.newPostItem.postImages = this.FileTest;

    //var a = this.fileService.uploadPostImage(14, this.file, 4, this.newPostItem).subscribe(res => {
    //  var result = res.json();
    //  console.log('post result');
    //  console.log(result);
    //  if (result.success == true) {
    //    this.newPostItem = result.Item;

   
    //  }
    this.spinner.show();
    this.postService.savePost(this.newPostItem).subscribe(res => {
      var result = res.json();
      if (result.success == true) {

        if (this.fileList.length > 0) {
          this.postService.savePostImage(0, result.item.index, this.fileList[0]).subscribe(res => {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 5000);
            if (this.fileList.length > 1) {
              this.spinner.show();
              this.postService.savePostImage(1, result.item.index, this.fileList[1]).subscribe(res => {
                setTimeout(() => {
                  /** spinner ends after 5 seconds */
                  this.spinner.hide();
                }, 5000);
                if (this.fileList.length > 2) {
                  this.spinner.show();
                  this.postService.savePostImage(2, result.item.index, this.fileList[2]).subscribe(res => {
                    setTimeout(() => {
                      /** spinner ends after 5 seconds */
                      this.spinner.hide();
                    }, 5000);
                    if (this.fileList.length > 3) {
                      this.spinner.show();
                      this.postService.savePostImage(3, result.item.index, this.fileList[3]).subscribe(res => {
                        setTimeout(() => {
                            /** spinner ends after 5 seconds */
                            this.spinner.hide();
                        }, 5000);
                        if (this.fileList.length > 4) {
                          this.spinner.show();
                          this.postService.savePostImage(4, result.item.index, this.fileList[4]).subscribe(res => {
                            setTimeout(() => {
                              /** spinner ends after 5 seconds */
                              this.spinner.hide();
                            }, 5000);
                            if (this.fileList.length > 5) {
                              this.spinner.show();
                              this.postService.savePostImage(5, result.item.index, this.fileList[5]).subscribe(res => {
                                setTimeout(() => {
                                  /** spinner ends after 5 seconds */
                                  this.spinner.hide();
                                }, 5000);
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
        //this.display = false;
        //window.location.reload();
        //this.router.navigate(['/home'])
      }
    });
   //  });
  }

  private sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  //hide(hide: any) {
  //  this.show.emit(!hide);
  //}

  public imageOption(imageCount: number) {
    if (imageCount === 1) {
      this.current = 0;
      this.one = true;
      this.two = false;
      this.three = false;
      this.four = false;
      this.five = false;
    } else if (imageCount === 2) {
      this.current = 1;
      this.one = false;
      this.two = true;
      this.three = false;
      this.four = false;
      this.five = false;
    } else if (imageCount === 3) {
      this.current = 2;
      this.one = false;
      this.two = false;
      this.three = true;
      this.four = false;
      this.five = false;
    } else if (imageCount === 4) {
      this.current = 3;
      this.one = false;
      this.two = false;
      this.three = false;
      this.four = true;
      this.five = false;
    } else {
      this.current = 4;
      this.one = false;
      this.two = false;
      this.three = false;
      this.four = false;
      this.five = true;
    }
  }

  public prev() {
    switch (this.current) {
      case 0:
        this.current = 4;
        this.one = false;
        this.two = false;
        this.three = false;
        this.four = false;
        this.five = true;
        break;
      case 1:
        this.current = 0;
        this.one = true;
        this.two = false;
        this.three = false;
        this.four = false;
        this.five = false;
        break;
      case 2:
        this.current = 1;
        this.one = false;
        this.two = true;
        this.three = false;
        this.four = false;
        this.five = false;
        break;
      case 3:
        this.current = 2;
        this.one = false;
        this.two = false;
        this.three = true;
        this.four = false;
        this.five = false;
        break;
      case 4:
        this.current = 3;
        this.one = false;
        this.two = false;
        this.three = false;
        this.four = true;
        this.five = false;
        break;

      default:

        break;
    }
  }

  public next() {
    switch (this.current) {
      case 0:
        this.current = 1;
        this.one = false;
        this.two = true;
        this.three = false;
        this.four = false;
        this.five = false;
     
      break;
      case 1:
        this.current = 2;
        this.one = false;
        this.two = false;
        this.three = true;
        this.four = false;
        this.five = false;
      break;
      case 2:
        this.current = 3;
        this.one = false;
        this.two = false;
        this.three = false;
        this.four = true;
        this.five = false;
      break;
      case 3:
        this.current = 4;
        this.one = false;
        this.two = false;
        this.three = false;
        this.four = false;
        this.five = true;
      break;
      case 4:
        this.current = 0;
        this.one = true;
        this.two = false;
        this.three = false;
        this.four = false;
        this.five = false;
      break;

    default:

      break;
    }
  }

}
