import { PostType } from "../enums/post.type";

export class BigPost
{
  public mainTitle: string;
  public subTitle: string;
  public date: Date;
  public author: string;
  public post: string;
  public type: PostType;
  public index: number;
  public postImage: string;
  public iFrameUrl: string;

  //constructor( public initMainTitle: string, public initSubTitle: string, public initDate: Date, public initAuthor: string, public initPost: string, initType:  PostType ) {
  //  this.mainTitle = initMainTitle;
  //  this.subTitle = initSubTitle;
  //  this.date = initDate;
  //  this.author = initAuthor;
  //  this.post = initPost;
  //  this.type = initType;
  //  this.index = 0;
  //}
}

