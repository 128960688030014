import { Component, OnInit, Input } from '@angular/core';
import { SideBarCard } from '../../models/sidebar.card/sidebar.card';
import { Global } from '../../../general/global';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-small-post-main',
  templateUrl: './small-post-main.component.html',
  styleUrls: ['./small-post-main.component.less']
})
export class SmallPostMainComponent implements OnInit {

  @Input() sidebarCardData: SideBarCard[];

  public isAdmin: boolean;
  private messageSub: Subscription;

  constructor(private global : Global) {this.sidebarCardData = [];}

  ngOnInit() {
    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

}
