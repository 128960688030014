export class TrendingPost {
  public postImage:string;
  public mainTitle:string;
  public subTitle:string;
  public date:string;
  public author:string;

  constructor(public initPostImage:string, public initMainTitle: string,public initSubTitle: string,public initDate:string, public initAuthor:string) {
    this.postImage = initPostImage;
    this.mainTitle = initMainTitle;
    this.subTitle = initSubTitle;
    this.date = initDate;
    this.author = initAuthor;
  }
}
