import { Component, OnInit } from '@angular/core';
import { SubscriberService } from '../../../services/subscriber-service';

@Component({
  selector: 'app-news-letter-signup',
  templateUrl: './news-letter-signup.component.html',
  styleUrls: ['./news-letter-signup.component.less']
})
export class NewsLetterSignupComponent implements OnInit {
  public email: string;
  public sentWasSuccessFul: boolean;
  public emailValid:boolean;
  public sentMessage: string;


  constructor(private subscriberService: SubscriberService) {
    this.email = '';
    this.sentMessage = 'Thank you for subscribing!';
    this.emailValid = true;
  }

  ngOnInit() {
  }

  public subscribe() {
    this.subscriberService.subscribe(this.email).subscribe( 
      res => {
        this.sentWasSuccessFul = res.json();

      });;
  }

  public onEmailChanged() {
    if (this.email.includes('@') && this.email.includes('.')) {
      this.emailValid = true;
    } else {
      this.emailValid = false;
    }

  }

}
