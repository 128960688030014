import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Global } from '../../general/global';
import { About } from '../models/About/about';
import { AboutService } from '../../services/about-service';
import { FileService } from '../../services/file-service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { MainPageService } from '../../services/mainPage.service';
import { ProfileSummary } from '../models/profile.summary/profile.summary';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryComponent implements OnInit {

  public about: About;

  public isAdmin: boolean;
  public updateSuccessful: boolean;

  public profileSummary: ProfileSummary;
  public saveSuccessful: string;

  private messageSub: Subscription;

  constructor(private global: Global, private aboutService: AboutService, private fileService: FileService, private ser: MainPageService, private spinner: NgxSpinnerService) {
    this.about = new About();

    this.updateSuccessful = false;

    this.saveSuccessful = 'Update was successful!';

    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);
  }

  ngOnInit() {
    this.spinner.show();

    window.scroll(0, 0);

    //hide nav menu
    document.getElementById('collapseBtn').classList.add("collapsed");
    document.getElementById('navbarSupportedContent').classList.remove("show");
    this.aboutService.getAboutPage().subscribe(res => {
      var result = res.json();
      if (result.success) {
        //setTimeout(() => {
          this.about.aboutMeHeader = result.item.aboutMeHeader;
          this.about.aboutMeBody = result.item.aboutMeBody;
          this.about.aboutMeImage = result.item.aboutMeImage;
          this.about.images = [...result.item.images];
          this.ser.getMainPage().subscribe(
            res => {
              res = res.json();
              this.profileSummary = res['item'].profileSummary;
              this.spinner.hide();
            });
        //}, 2000);
      }
    });

    //this.aboutService.saveAboutPage(this.about).subscribe(res => {
    //  var result = res.json();
    //  console.log(result);
    //if (result.success) {
    //  this.about.aboutMeHeader = result.item.aboutMeHeader;
    //  this.about.aboutMeBody = result.item.aboutMeHeader;
    //  this.about.aboutMeImage = result.item.aboutMeHeader;

    //  this.about.images.push('../../assets/img/bg-img/7.jpg');
    //  this.about.images.push('../../assets/img/bg-img/7.jpg');
    //  this.about.images.push('../../assets/img/bg-img/7.jpg');
    //}
    //});

  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

  public updateAboutData() {
    console.log(this.about);
    this.aboutService.saveAboutPage(this.about).subscribe(res => {
      var result = res.json();

      this.updateSuccessful = result;
    });
  }

  public upload(event, imageId: number, componentId: number) {
    var fileToUpload = event.files[0];

    this.fileService.upload(imageId, fileToUpload, componentId).subscribe(res => {

      window.location.reload();

    });
  }
}
