import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { Constants, LIVE } from '../general/constants';

@Injectable()
export class IndexService {
  public headers: Headers;
  public response: any;
  private host: string;
  private live = true;

  constructor(private http: Http) {
    this._setAuthHeaders;
    if (LIVE) {
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }
  }

  public getIndex() {
    return this.http.get(this.host +"/api/index", { headers: this.headers });
    //this.response = 'Your message was sent';
  }

  public test() {
    return this.http.put(this.host + "/api/trasnfer", {Index:0, },{ headers: this.headers });
    //this.response = 'Your message was sent';
  }


  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}


export class BlockInfo {
  public Index: number;
} 
