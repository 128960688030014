import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { Constants, LIVE} from '../general/constants';

@Injectable()
export class MainPageService
{
  public headers: Headers;
  public response: any;
  private host: string;

  constructor(private http: Http) {
    if (LIVE) {
      this.host = Constants.hostLive
    } else {
      this.host = Constants.host
    }
  }

  public getMainPage() {
    return this.http.get(this.host +"/api/mainpage", { headers: this.headers });

    //this.response = 'Your message was sent';
  }


  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}
