import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FooterItem } from './models/footer/footer.item';
import { IndexService } from '../services/index.service';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { FileUploadModule } from 'primeng/fileupload';
import { FileService } from '../services/file-service';
import { Global } from '../general/global';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileSummary } from './models/profile.summary/profile.summary';
import { MainPageService } from '../services/mainPage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  public title: string;
  public mainImage: string;
  public imageHeight: string;
  public logoPaddingTop: string;
  public logoImage: string;
  public currentRoute: string;
  public role: string;
  public isHidden:string;
  public showSideBar: boolean;

  public footerImages: FooterItem[];
  public profileSummary: ProfileSummary;

  public toggleClass: boolean;
  public home: boolean;
  public about: boolean;
  public contact: boolean;
  public showUpdate: boolean;
  public isAdmin: boolean;
  template: string = `<img src="http://pa1.narvii.com/5722/2c617cd9674417d272084884b61e4bb7dd5f0b15_hq.gif" />`

  uploadedFiles: any[] = [];

  private messageSub: Subscription;

  constructor(private indexService: IndexService, private router: Router, private fileService: FileService, private global: Global, private spinner: NgxSpinnerService, private mainService: MainPageService,) {
    this.home = true;
    this.about = false;
    this.contact = false;

    this.title = 'TatiCamarWebsite';
    this.toggleClass = false;
    this.footerImages = [];
    this.currentRoute = '';

    this.showUpdate = false;
  }

  ngOnInit() {
   
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
          this.showSideBar = true;
          if (event.url === '/admin') {
            this.role = "admin";
          } else if (event.url === '/' || event.url === '/home') {
            this.imageHeight = '850px';
            this.role = "user";
            this.isHidden = 'visible';
          } else {
            this.logoPaddingTop = '100px';
            this.imageHeight = '0px';
            this.role = "user";
            this.isHidden = 'hidden';
          }

          console.log(this.role);

          var admin = localStorage.getItem("ud");

          if (admin === "Admin") {
            this.global.setAdmin(true);
          }
        }

      });
   // this.indexService.
    

    this.indexService.getIndex().subscribe(res => {
      res = res.json();   
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 5000);

      this.mainImage = res['item'].mainImagePath;

      this.logoImage = res['item'].logoPath;

      res['item'].listOfFooterImagesPath.forEach(item => {

        var footer = new FooterItem(item);

        this.footerImages.push(footer);
      });

     
    });

    this.messageSub = this.global.isAdmin.subscribe(x => {
      this.isAdmin = x;
    });

    this.mainService.getMainPage().subscribe(
      res => {

        res = res.json();console.log(res);
        this.profileSummary = res['item'].profileSummary;
      });
  }

  ngafterviewinit() {
    document.getElementById('logImg').classList.remove("shrink");
  }


  ngOnDestroy() {
    this.messageSub.unsubscribe();
    window.removeEventListener('scroll', this.scroll, true);
  }

  public toggle(): void {

    if (this.toggleClass) {

      this.toggleClass = !this.toggleClass;

    } else {

      this.toggleClass = !this.toggleClass;

    }
  }

  public update() {
    this.showUpdate = !this.showUpdate;
  }

  upload(event, imageId: number, componentId: number) {
    var fileToUpload = event.files[0];

    this.fileService.upload(imageId, fileToUpload, componentId).subscribe(res => {
      this.router.navigate(['home'])
        //window.location.reload();
    });
  }

  myUploader(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  public logout() {
    localStorage.removeItem("ud");
   this.global.setAdmin(false);
    this.router.navigate(['home'])
    //window.location.reload();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  scroll = (event: any): void => {
    console.log(event);
  };
  //this.router.navigateByUrl(path);
}
