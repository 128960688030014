import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { Constants, LIVE} from '../general/constants';

@Injectable()
export class PostService {
  public headers: Headers;
  public response: any;
  private host: string;
  private live = true;

  constructor(private http: Http) {
    if (LIVE) {
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }}

  public savePost(newpost: any) {
    //let input = new FormData();
    //fileList.forEach(file => {
    //  input.append("file", file);
    //});
    //input.append("post", JSON.stringify(newpost));
    return this.http.post(this.host + "/api/post/new", newpost ,  { headers: this.headers });
  }

  public savePostImage(imagePosition:number, postPosition:number, file:any) {
     let input = new FormData();
    input.append("file", file);
    return this.http.post(this.host + "/api/post/images/" + imagePosition + "/" + postPosition, input, { headers: this.headers });
  }

  public getPost(index:number) {
    return this.http.get(this.host + "/api/post/"+index,  { headers: this.headers });
  }


  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}
