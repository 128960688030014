import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { FileData } from '../app/models/file-data';
import { Constants, LIVE } from '../general/constants';

@Injectable()
export class FileService {
  public headers: Headers;
  public response: any;
  private host: string;

  constructor(private http: Http) {
   // this._setAuthHeaders();
    if (LIVE) {
      console.log('At file service');
      console.log(LIVE);
      console.log(this.host = Constants.hostLive);
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }
  }

  public upload(imageId:number, file: any, componentId: number) {
    let input = new FormData();
    input.append("file", file);
    return this.http.post(this.host +"/api/upload/" + imageId + "/"+componentId, input ,{ headers: this.headers });
  }

  //public uploadPostImage2(imageId: number, file: any, componentId: number, newPost: any) {
  //  return this.http.post("http://localhost:55773/api/test" + imageId + "/" + componentId,
  //    input,
  //    { headers: this.headers });
  //}

  public uploadPostImage(imageIndex: number, file: any) {
    let input = new FormData();
    input.append("file", file);
    
    return this.http.put(this.host +"/api/upload/post/image/" + imageIndex , input, { headers: this.headers })
  }
  
  

  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}
