import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { Message } from '../app/models/messages/message';
import { RelationshipLink } from '../app/models/messages/relationshipLink';
import { MessageActionState } from '../app/models/messages/messageActionState';
import { Constants, LIVE} from '../general/constants';

@Injectable()
export class MessageService {
  public headers: Headers;
  public response: any;
  private host: string;
  private live = true;

  constructor(private http: Http) {
    this._setAuthHeaders();

    if (LIVE) {
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }

  }

  public sendMessage(name: string, email: string, contentMessage: string) :Observable<Response> {

    const message = new Message();

    message.SenderRecipient = new RelationshipLink();
    message.ActionState = new MessageActionState();

    message.ContentMessage = contentMessage;
    message.SenderRecipient.Sender = name;
    message.SenderRecipient.SenderEmail = email;
    message.SenderRecipient.SenderPhone = '';
    message.ActionState.opened = false;
    message.ActionState.deleted = false;
    message.ActionState.deletedForEver = false;

    message.Platform = 0;

    return this.http.post(Constants.messagesApiUrl+"/api/TatiCamar", message, { headers: this.headers });
  }

  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
}
