import { Component, OnInit, Input } from '@angular/core';
import { FooterItem } from '../../models/footer/footer.item';
import { FileUploadModule } from 'primeng/fileupload';
import { Global } from '../../../general/global';
import { Subscription } from 'rxjs';
import { FileService } from '../../../services/file-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent {

  @Input() listOfItems: FooterItem[];
  public images = [];
  public isAdmin: boolean;
  private messageSub: Subscription;
  uploadedFiles: any[] = [];

  constructor(private global: Global, private fileService: FileService) {
   
  }

  ngOnInit() {
    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);

    this.listOfItems.forEach(item => {
      this.images.push(item.path)
    });
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

  upload(event, imageId:number, componentId:number) {
    var fileToUpload = event.files[0];

    this.fileService.upload(imageId, fileToUpload, componentId).subscribe(res => {
    
        window.location.reload();
     
    });
  }

  myUploader(event) {
  }
}
