import { Component, OnInit, Input } from '@angular/core';
import { ProfileSummary } from '../../models/profile.summary/profile.summary';
import { FileUploadModule } from 'primeng/fileupload';
import { Global } from '../../../general/global';
import { Subscription } from 'rxjs';
import { FileService } from '../../../services/file-service';
import { ProfileService } from '../../../services/profile-service';

@Component({
  selector: 'app-profile-overview',
  templateUrl: './profile-overview.component.html',
  styleUrls: ['./profile-overview.component.less']
})
export class ProfileOverviewComponent implements OnInit {

  @Input() profileData : ProfileSummary;
  public isAdmin:boolean;
  private messageSub: Subscription;

  public saveSuccessful: string;
  public updateSuccessful: boolean;

  constructor(private global: Global, private fileService: FileService, private profileService: ProfileService) {
    this.updateSuccessful = false;
    this.saveSuccessful = 'Update was successful';
  }

  ngOnInit() {
    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

  public upload(event, imageId: number, componentId: number) {
    var fileToUpload = event.files[0];

    this.fileService.upload(imageId, fileToUpload, componentId).subscribe(res => {
     
        window.location.reload();
     
    });
  }

  public myUploader(event) {

    //for (let file of event.files) {
    //  this.uploadedFiles.push(file);
    //}

    //for (let file of event.files) {
    //  this.fileService.upload(file).subscribe(res => {
    //    res = res.json();

    //    console.log(res);

    //  });
    //}
  }

  public updateSummary() {
    if (this.profileData.summary != '') {

      this.profileService.updateProfile(this.profileData).subscribe((res) => {
        this.updateSuccessful = res.json();
      });
    }
  }

}
