import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-add',
  templateUrl: './left-add.component.html',
  styleUrls: ['./left-add.component.less']
})
export class LeftAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
