import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CategoryComponent } from './about/category.component';
import { CategoryPostComponent } from './category-post/category-post.component';
import { ContactComponent } from './contact/contact.component';
import { ReceipeComponent } from './receipe/receipe.component';
import { SinglePostComponent } from './single-post/single-post.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OwlModule } from 'ngx-owl-carousel';
import { HomeSubComponent } from './home-sub/home-sub.component';
import { FooterComponent } from './components/footer/footer.component';
import { TrendingPostComponent } from './components/trending.post/trending.post.component';
import { BigPostComponent } from './components/big-post/big-post.component';
import { BigPostImageRightComponent } from './components/big-post-image-right/big-post-image-right.component';
import { PostsHolderComponent } from './components/posts-holder/posts-holder.component';
import { MainPageService } from '../services/mainPage.service';
import { IndexService } from '../services/index.service';
import { ProfileOverviewComponent } from './components/profile-overview/profile-overview.component';
import { NewsLetterSignupComponent } from './components/news-letter-signup/news-letter-signup.component';
import { SmallPostMainComponent } from './components/small-post-main/small-post-main.component';
import { AdminComponent } from './admin/admin.component';

import { FieldsetModule } from 'primeng/fieldset';
import { UserService } from '../services/user.service';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';


//PRIMENG
import { LightboxModule } from 'primeng/lightbox';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { GalleriaModule } from 'primeng/galleria';
//PRIMENG


//text editor
import { NgxEditorModule } from 'ngx-editor';
///

//ellipsis
import { NgxMultiLineEllipsisModule } from "ngx-multi-line-ellipsis";
//

//spinner
import { NgxSpinnerModule } from 'ngx-spinner';
//

//gallery
import { NgxGalleryModule } from 'ngx-gallery';
//

import { FileService } from '../services/file-service';
import { Global } from '../general/global';
import { MessageService } from '../services/message-service';
import { SubscriberService } from '../services/subscriber-service';
import { ProfileService } from '../services/profile-service';
import { ContactService } from '../services/contact-service';
import { AboutService } from '../services/about-service';
import { AddPostModalComponent } from './components/add-post-modal/add-post-modal.component';
import { PostService } from '../services/post-service';
import { EditPostComponent } from './edit-post/edit-post.component';
import { ReadMorePostComponent } from './components/read-more-post/read-more-post.component';
import { LeftAddComponent } from './components/left-add/left-add.component';
import { AuthGuardService } from '../services/auth-service';
import { ValidateService } from '../services/validate-service';
import { ReadMorePostGalleryComponent } from './components/read-more-post-gallery/read-more-post-gallery.component';
import { AddPostComponent } from './add-post/add-post.component';
import { BuyThePostComponent } from './components/buy-the-post/buy-the-post.component';
import { ReadMorePostCommentsComponent } from './components/read-more-post-comments/read-more-post-comments.component';
import { CommentService } from '../services/comment-service';


@NgModule({
  declarations: [
    AppComponent,
    CategoryComponent,
    CategoryPostComponent,
    ContactComponent,
    ReceipeComponent,
    SinglePostComponent,
    HomeComponent,
    PageNotFoundComponent,
    HomeSubComponent,
    FooterComponent,
    TrendingPostComponent,
    BigPostComponent,
    BigPostImageRightComponent,
    PostsHolderComponent,
    ProfileOverviewComponent,
    NewsLetterSignupComponent,
    SmallPostMainComponent,
    AdminComponent,
    AdminHomeComponent,
    AddPostModalComponent,
    EditPostComponent,
    ReadMorePostComponent,
    LeftAddComponent,
    ReadMorePostGalleryComponent,
    AddPostComponent,
    BuyThePostComponent,
    ReadMorePostCommentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OwlModule,
    HttpModule,
    FormsModule,
    BrowserAnimationsModule,
    FieldsetModule,
    FileUploadModule,
    HttpClientModule,
    LightboxModule,
    DialogModule,
    DropdownModule,
    PaginatorModule,
    NgxEditorModule,
    NgxSpinnerModule,
    NgxMultiLineEllipsisModule,
    GalleriaModule,
    NgxGalleryModule
  ],
  providers: [MainPageService, IndexService, UserService, FileService,
    Global, MessageService, SubscriberService, ProfileService, ContactService,
    AboutService, PostService, AuthGuardService, ValidateService, CommentService],
  bootstrap: [AppComponent]
})
export class AppModule { }
