import { Component, OnInit, Input } from '@angular/core';
import { TrendingPost } from '../../models/trending.post/trending.post';

@Component({
  selector: 'app-trending-post',
  templateUrl: './trending.post.component.html',
  styleUrls: ['./trending.post.component.less']
})
export class TrendingPostComponent implements OnInit {

  @Input() visibility:boolean;

  public listOfItems: TrendingPost[];

  constructor() { this.listOfItems = [];}

  ngOnInit() {
    const item = new TrendingPost("../assets/img/bg-img/insta1.jpg", "The Best", "Friend eggs with ham", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item);
    const item2 = new TrendingPost("../assets/img/bg-img/insta2.jpg", "The Best", "Mushrooms with pork chop", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item2);
    const item3 = new TrendingPost("../assets/img/bg-img/insta3.jpg", "The Best", "Birthday cake with chocolate", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item3);
    const item4 = new TrendingPost("../assets/img/bg-img/insta4.jpg", "The Best", "Friend eggs with ham", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item4);
    const item5 = new TrendingPost("../assets/img/bg-img/insta5.jpg", "The Best", "Mushrooms with pork chop", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item5);
    const item6 = new TrendingPost("../assets/img/bg-img/insta6.jpg", "The Best", "Birthday cake with chocolate", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item6);
    const item7 = new TrendingPost("../assets/img/bg-img/insta7.jpg", "The Best", "Friend eggs with ham", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item7);
    const item8 = new TrendingPost("../assets/img/bg-img/insta8.jpg", "The Best", "Mushrooms with pork chop", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item8);
    const item9 = new TrendingPost("../assets/img/bg-img/insta9.jpg", "The Best", "Birthday cake with chocolate", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item9);
    const item10 = new TrendingPost("../assets/img/bg-img/insta10.jpg", "The Best", "Friend eggs with ham", "July 11, 2018", "By Julia Stiles");
    this.listOfItems.push(item10);
  }

  public close() {
    this.visibility = false;
  }
}
