import { Component, OnInit, Input } from '@angular/core';
import { BigPost } from '../../models/big.post/big.post';
import { FileService } from '../../../services/file-service';
import { Global } from '../../../general/global';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-big-post-image-right',
  templateUrl: './big-post-image-right.component.html',
  styleUrls: ['./big-post-image-right.component.less']
})
export class BigPostImageRightComponent implements OnInit {

  @Input() public item: BigPost;
  @Input() public imageIndex: number;
  public isAdmin: boolean;
  private messageSub: Subscription;

  constructor(private fileService: FileService, private global: Global) {
  }

  ngOnInit() {
    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);

    this.item.post = this.item.post.substring(0, 400);

    this.item.post = this.item.post + "...";
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

  public myUploader(event) {
    this.fileService.uploadPostImage(this.imageIndex, event.files[0]).subscribe(res => {
      var result = res.json();
      if (result.success == true) {
        window.location.reload();
      }
     });
  }
}
