import { Component } from '@angular/core';
import { MainPageService } from '../../services/mainPage.service';
import { BigPost } from '../models/big.post/big.post';
import { ProfileSummary } from '../models/profile.summary/profile.summary';
import { SideBarCard } from "../models/sidebar.card/sidebar.card";
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

//import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent {

  public bigPost: BigPost[];
  public profileSummary: ProfileSummary;
  public sideBarCards: SideBarCard[];

  private sub: any;

  constructor(private mainService: MainPageService, private route: ActivatedRoute, private spinner: NgxSpinnerService) {
    this.bigPost = [];
    this.sideBarCards = [];
    this.profileSummary = null;
  }

  ngOnInit() {
    this.spinner.show();
    //this.sub = this.route.params.subscribe(params => {

    // In a real app: dispatch action to load the details here.
    // });
    //this.spinner.show();

    window.scroll(0, 0);

    //hide nav menu
    document.getElementById('collapseBtn').classList.add("collapsed");
    document.getElementById('navbarSupportedContent').classList.remove("show");
    this.mainService.getMainPage().subscribe(
      res => {
        //setTimeout(() => {
          res = res.json(); console.log(res);
          this.bigPost = res['item'].listOfBigPosts;
          this.profileSummary = res['item'].profileSummary;
          this.sideBarCards = res['item'].sideBarCards;
          this.spinner.hide();
        //}, 2000);
      });
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }

}
