import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-post',
  templateUrl: './category-post.component.html',
  styleUrls: ['./category-post.component.less']
})
export class CategoryPostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
