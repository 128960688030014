import { Component, OnInit, Input } from '@angular/core';
import { IComment } from '../../models/big.post/comment';
import { CommentService } from '../../../services/comment-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-read-more-post-comments',
  templateUrl: './read-more-post-comments.component.html',
  styleUrls: ['./read-more-post-comments.component.less']
})
export class ReadMorePostCommentsComponent implements OnInit {
  @Input() listOfComments: IComment[];
  @Input() postId: number;
  public comment: IComment ;

  constructor(private commentService: CommentService, private spinner: NgxSpinnerService) {
   
  }

  ngOnInit() {
    this.comment = { PostId: this.postId, Commentor: '', CommentorEmail: '', Message: '', Reply: '',Date:new Date() }
  }

  public submitComment() {
    this.spinner.show();
    this.commentService.saveComment(this.comment).subscribe(res => {
      var result = res.json();
      if (result.success == true) {
        this.listOfComments.push(result.item);
        console.log(result.item);
        this.spinner.hide();
      } else {
        console.log(result);
        this.spinner.hide();
      }
    });
  }

}
