import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './about/category.component';
import { CategoryPostComponent } from './category-post/category-post.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SinglePostComponent } from './single-post/single-post.component';
import { ReceipeComponent } from './receipe/receipe.component';
import { ContactComponent } from './contact/contact.component';
import { AdminComponent } from './admin/admin.component';
import { EditPostComponent } from './edit-post/edit-post.component';
import { ReadMorePostComponent } from './components/read-more-post/read-more-post.component';
import {AuthGuardService as AuthGuard} from "../services/auth-service";
import { AddPostComponent } from './add-post/add-post.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent,
    canActivate: [AuthGuard] 
  },
  { path: 'home:user', component: HomeComponent },
  { path: 'about', component: CategoryComponent }, 
  { path: 'categorypost', component: CategoryPostComponent }, 
  { path: 'singlepost', component: SinglePostComponent }, 
  { path: 'receipe', component: ReceipeComponent }, 
  { path: 'contact', component: ContactComponent }, 
  { path: 'admin', component: AdminComponent }, 
  { path: 'editPost', component: EditPostComponent },
  { path: 'readmore/:id', component: ReadMorePostComponent },
  { path: 'addpost', component: AddPostComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor() { }

}


