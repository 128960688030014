export class ProfileSummary
{
  public mainImagePath: string;
  public backgroundImage: string;
  public socialMediaIconsPath: string[];
  public summary: string;

  constructor(public mainimage: string, public socialicons: string[], public sum: string, public bgImage:string) {
    this.mainImagePath = mainimage;
    this.socialMediaIconsPath = socialicons;
    this.summary = sum;
    this.backgroundImage = bgImage;
  }
}
