import { Component, OnInit } from '@angular/core';
import { ProfileSummary } from '../models/profile.summary/profile.summary';
import { MainPageService } from '../../services/mainPage.service';
import { MessageService } from '../../services/message-service';
import { Subscription } from 'rxjs';
import { Global } from '../../general/global';
import { ContactData } from '../models/contact/contactData';
import { Contact } from '../models/contact/contact';
import { ContactService } from '../../services/contact-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit {

  public profileSummary: ProfileSummary;
  public name: string;
  public email: string;
  public message: string;
  public successfulSentMessage: string;
  public saveSuccessful: string;
  public contact: Contact;

  public isAdmin:boolean;

  public messageSentSuccessful: boolean;
  public updateSuccessful: boolean;
  public emailValid: boolean;

  private messageSub: Subscription;

  constructor(private ser: MainPageService, private messageService: MessageService, private global: Global, private contactservice: ContactService, private spinner: NgxSpinnerService) {
    this.contact = new Contact(); 
    this.profileSummary = null;
    this.name = '';
    this.email = '';
    this.message = '';
    this.messageSentSuccessful = false;
    this.updateSuccessful = false;
    this.isAdmin = false;
    this.successfulSentMessage = 'Thank you for contacting us! I\'ll be in contact with you as soon as I can! Tati  ';
    this.saveSuccessful = 'Update was successful';

    this.contact.contactHeader = '';
    this.contact.contactBody = '';
    this.contact.contactInfo.email = '';

    this.emailValid = true;
  }

  ngOnInit() {
    this.spinner.show();

    window.scroll(0, 0);

    //hide nav menu
    document.getElementById('collapseBtn').classList.add("collapsed");
    document.getElementById('navbarSupportedContent').classList.remove("show");
    //this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);
    //setTimeout(() => { 
    this.ser.getMainPage().subscribe(
      res => {
        res = res.json();
        this.profileSummary = res['item'].profileSummary;
      });

    this.contactservice.getContactPage().subscribe((res) => {
      //setTimeout(() => { 
        this.spinner.hide();

        var result = res.json();
        if (result.success == true) {
          this.contact.id = result.item.id;
          this.contact.contactHeader = result.item.contactHeader;
          this.contact.contactBody = result.item.contactBody;
          this.contact.contactInfo.email = result.item.contactInfo.email;
        }
      //}, 2000);
    });
   
    
  }

  ngOnDestroy() {
    //this.messageSub.unsubscribe();
  }

  public sendMessage() {
    this.messageService.sendMessage(this.name, this.email, this.message).subscribe( ///www.customersvault.com
      res => {
        this.messageSentSuccessful =  res.json();
      });;
  }

  public onEmailChange() {
    if (this.email.includes('@') && this.email.includes('.')) {
      this.emailValid = true;
    } else {
      this.emailValid = false;
    }
  }

  public updateContactPage() {
    this.contactservice.saveContactPage(this.contact).subscribe((res) => {
      this.updateSuccessful = res.json();
    })
  }

}
