import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Global } from '../../../general/global';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PostService } from '../../../services/post-service';
import { BigPost } from '../../models/big.post/big.post';
import { PostType } from '../../models/enums/post.type';
import { SelectItem } from 'primeng/api';
import { Route, Router } from '@angular/router';
import { FileService } from '../../../services/file-service';

@Component({
  selector: 'app-add-post-modal',
  templateUrl: './add-post-modal.component.html',
  styleUrls: ['./add-post-modal.component.less']
})
export class AddPostModalComponent implements OnInit {

  @Input() display: boolean;
  @Output() show = new EventEmitter<boolean>();

  public step1: boolean;
  public step2: boolean;
  public step3: boolean;
  public isAdmin: boolean;

  public file: any;

  public types: SelectItem[];
  public selectedType: any;

  public newPostItem: BigPost;

  public imageTemp: SafeUrl;

  private messageSub: Subscription;

  private htmlContent:any;

  constructor(private route: Router, private global: Global, private sanitizer: DomSanitizer, private postService: PostService,  private fileService: FileService) {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
  }

  ngOnInit() {
    this.newPostItem = new BigPost();

    this.types = [
      { label: 'Select Type', value: null },
      { label: PostType[PostType.IMAGELEFT], value: { id: 1, name: PostType[PostType.IMAGELEFT], type: PostType.IMAGELEFT } },
      { label: PostType[PostType.IMAGERIGHT], value: { id: 2, name: PostType[PostType.IMAGERIGHT], type: PostType.IMAGERIGHT } }
    ];

    // this.types = [PostType[PostType.IMAGELEFT], PostType[PostType.IMAGERIGHT]];
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;

    this.messageSub = this.global.isAdmin.subscribe(x => this.isAdmin = x);
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }

  public newPost() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
  }

  public toStep2() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }

  public review() {

    console.log(this.selectedType);
    console.log(this.selectedType.type);

    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
  }

  public myUploader(event) {
    console.log(event);

  }

  public before(event) {
    this.imageTemp = this.sanitize(event.files[0].objectURL.changingThisBreaksApplicationSecurity);

  }

  public upload(event) {
    console.log();
    this.file = event.files[0];
    //this.fileService.uploadPostImage(14, event.files[0], 4).subscribe(res => {
    //  var result = res.json();

    //  if (result.success == true) {
    //    this.newPostItem = result.Item;

    //    console.log(result);
    //  }
    //});
    //var tempImage = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
    // this.imageTemp = tempImage;
    //var img = new Image();

    //const imageUrl = URL.createObjectURL(tempImage);

    //console.log(imageUrl);
    //this.imageTemp = this.sanitize(tempImage);

    // console.log(img);
    //console.log(event.files[0]);
    // console.log(event);
  }

  public savePost() {
    this.newPostItem.type = this.selectedType.type;

    //var a = this.fileService.uploadPostImage(14, this.file, 4, this.newPostItem).subscribe(res => {
    //  var result = res.json();
    //  console.log('post result');
    //  console.log(result);
    //  if (result.success == true) {
    //    this.newPostItem = result.Item;

    //    console.log(result);
    //  }
      this.postService.savePost(this.newPostItem).subscribe(res => {
        var result = res.json();

        if (result.success == true) {
          this.display = false;
          window.location.reload();
        }
      });
   // });
  }

  private sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  hide(hide: any) {
    this.show.emit(!hide);
  }
}
