import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { Constants, LIVE } from '../general/constants';

@Injectable()
export class ContactService {
  public headers: Headers;
  public response: any;
  private host: string;
  private live = true;

  constructor(private http: Http) {
    if (LIVE) {
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }
  }

  public getContactPage() {
    return this.http.get(this.host +"/api/TatiCamar/contactUpdate", { headers: this.headers });

    //this.response = 'Your message was sent';
  }

  public saveContactPage(contact: any) {
    return this.http.post(this.host +"/api/TatiCamar/contactUpdate/new", contact ,{ headers: this.headers });

    //this.response = 'Your message was sent';
  }


  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}
