export class RelationshipLink {
  public Sender: string;
  public SenderEmail: string;
  public SenderPhone: string;
  public Recipient: string;

  public RelationshipLink() {
    this.Sender = '';
    this.SenderEmail = '';
    this.SenderPhone = '';
    this.Recipient = '';
  }
}
