import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { Constants, LIVE} from '../general/constants';

@Injectable()
export class AboutService {
  public headers: Headers;
  public response: any;
  private host:string;
  private live = true;

  constructor(private http: Http) {
    if (LIVE) {
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }

  }

  public getAboutPage() {
    return this.http.get(this.host+"/api/aboutpage", { headers: this.headers });

    //this.response = 'Your message was sent';
  }
  public saveAboutPage(aboutData: any) {
    return this.http.post(this.host+"/api/aboutpage/new", aboutData, { headers: this.headers });

    //this.response = 'Your message was sent';
  }


  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}
