import { Component, OnInit, Output, Input, EventEmitter, HostListener, } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-read-more-post-gallery',
  templateUrl: './read-more-post-gallery.component.html',
  styleUrls: ['./read-more-post-gallery.component.less']
})
export class ReadMorePostGalleryComponent implements OnInit {
  public listOfImages: string[];
  public ImagesRecords: any[];
  @Output() imageToView = new EventEmitter<string>();
  @Input() images: string[];
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() {
    this.listOfImages = [];
    this.ImagesRecords = [];
  }

  ngOnInit() {
    this.galleryOptions = [
      {
        "imageAutoPlay": true,
        "imageAutoPlayPauseOnHover": true,
        "previewAutoPlay": true,
        "previewAutoPlayPauseOnHover": true
      },
      { "breakpoint": 500, "width": "100%", "height": "80vh", "thumbnailsColumns": 3 },
      { "breakpoint": 300, "width": "100%", "height": "200px", "thumbnailsColumns": 2 },
      { "previewFullscreen": true, "previewKeyboardNavigation": true },
      { "previewZoom": true, "previewRotate": true },
      { "arrowPrevIcon": "fa fa-arrow-circle-o-left", "arrowNextIcon": "fa fa-arrow-circle-o-right", "closeIcon": "fa fa-window-close", "fullscreenIcon": "fa fa-arrows", "spinnerIcon": "fa fa-refresh fa-spin fa-3x fa-fw", },
    
      { "image": false, "thumbnailsRemainingCount": true, "height": "80px" },
      { "imageAnimation": "slide", width: "100%", height: "80vh" }
     
      
    

      //{
      //  width: '600px',
      //  height: '400px',
      //  thumbnailsColumns: 4,
      //  imageAnimation: NgxGalleryAnimation.Slide
      //},
      //// max-width 800
      //{
      //  breakpoint: 800,
      //  width: '100%',
      //  height: '600px',
      //  imagePercent: 80,
      //  thumbnailsPercent: 20,
      //  thumbnailsMargin: 20,
      //  thumbnailMargin: 20
      //},
      // max-width 400
      //{
      //  breakpoint: 400,
      //  preview: false
      //}
    ];


    this.galleryImages = [
      //{
      //  small: 'assets/img/uploads/1.jpg',
      //  medium: 'assets/img/uploads/1.jpg',
      //  big: 'assets/img/uploads/1.jpg'
      //},
      //{
      //  small: 'assets/img/uploads/1.jpg',
      //  medium: 'assets/img/uploads/1.jpg',
      //  big: 'assets/img/uploads/1.jpg'
      //},
      //{
      //  small: 'assets/img/uploads/1.jpg',
      //  medium: 'assets/img/uploads/1.jpg',
      //  big: 'assets/img/uploads/1.jpg'
      //}
    ];

    this.images.forEach(res => {
      if (res != '') {
        this.listOfImages.push(res);
        this.ImagesRecords.push({ source: res, alt: 'Description for Image 1', title: 'Title 1' });

        this.galleryImages.push({ small: res, medium: res, big: res});
      }
    });

   
  }

  public show(selectedImage: string) {
    this.imageToView.emit(selectedImage);
  }
}
