import { Component, OnInit } from '@angular/core';
import { FieldsetModule } from 'primeng/fieldset';
import { UserService } from '../../services/user.service';
import { User } from '../models/user/user';
import { Router } from '@angular/router';
import { Global } from '../../general/global';
import { Subscription } from 'rxjs';
import { ValidateService } from '../../services/validate-service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent implements OnInit {

  public username:string;
  public password: string;
  public error: string;
  public user:User;

  public isError: boolean;

  private messageSub: Subscription;

  constructor(private userService: UserService, private router: Router, private global: Global, private tokenService: ValidateService) {
    this.error= '';
    this.isError = false;
    this.user = new User('','','');
  }

  ngOnInit() {
    this.messageSub = this.global.isAdmin.subscribe(x => x);
  }

  ngOnDestroy() {
    this.messageSub.unsubscribe();
  }


  public signIn() {
    this.userService.validateUser(this.user).subscribe(res => {
      let response = res.json();

      if (response.success === true) {
        this.tokenService.getToken().subscribe(res2 => {
          let responseToken = res2.json();

          if (responseToken.success === true) {
            localStorage.removeItem("ud");
            localStorage.setItem("ud", response.item.role);
            localStorage.setItem("token", responseToken.item);
            this.isError = false;
            this.global.setAdmin(true);
            this.router.navigate(['home']);
          }
        });
      } else {
        this.error = response.error.Message;
        this.isError = true;
        this.global.setAdmin(false);
      }
    });
  }
}
