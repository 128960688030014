import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Constants, LIVE } from '../general/constants';

@Injectable()
export class ValidateService {
  public headers: Headers;
  public response: any;
  private host: string;
  private live = true;

  constructor(private http: Http) {
    if (LIVE) {
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }

  }

  public getToken() {
    return this.http.get(this.host + "/api/token/generate", { headers: this.headers });
  }


  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}
