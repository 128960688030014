export class User {
  public userName:string;
  public password:string;
  public role: string;

  constructor(private username: string, private setPassword: string, private setRole: string) {
    this.userName = username;
    this.password = setPassword;
    this.role = setRole;
  }
}
