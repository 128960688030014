import { ContactData } from "./contactData";

export class Contact {
  public id: any;
    public contactHeader: string;
    public contactBody: string;
  public contactInfo: ContactData;

  constructor() {
    this.contactHeader = '';
    this.contactBody = '';
    this.contactInfo = new ContactData();
  }
}
