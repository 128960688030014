import { RelationshipLink } from "./relationshipLink";
import { MessageActionState } from "./messageActionState";

export class Message {
  public ActionState: MessageActionState;
  public SenderRecipient: RelationshipLink;
  public ContentMessage: string;
  public Platform: number;

  public Message() {
    this.SenderRecipient = new RelationshipLink();
    this.ActionState = new MessageActionState();
    this.ContentMessage = '';
    this.Platform = 0;
  }
}

