import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { ProfileSummary } from "../app/models/profile.summary/profile.summary";
import { RelationshipLink } from '../app/models/messages/relationshipLink';
import { MessageActionState } from '../app/models/messages/messageActionState';
import { Constants, LIVE} from '../general/constants';

@Injectable()
export class ProfileService {
  public headers: Headers;
  public response: any;
  private host: string;
  private live = true;

  constructor(private http: Http) {
    this._setAuthHeaders();
    if (LIVE) {
      this.host = Constants.hostLive;
    } else {
      this.host = Constants.host;
    }

  }

  public updateProfile(profle: ProfileSummary): Observable<Response> {
    console.log(profle);
    return this.http.post(this.host +"/api/TatiCamar/profileUpdate", profle, { headers: this.headers });
  }

  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
}
