import {Injectable} from'@angular/core';
import { Observable, BehaviorSubject } from "rxjs";

@Injectable()
export class Global
{
  private messageSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public isAdmin =this.messageSource.asObservable();
  public role: string;

  constructor() {
    this.role = '';
  }

  public setAdmin(newState: boolean) {
    this.messageSource.next(newState);
  }

}
